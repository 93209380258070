<template>
    <div class="const_width">
        <div class="content_container">
            <p class="heading">İletişim</p>
            <v-col sm="9" class="mx-auto">
                <p class="content-text">
                    <strong>FutbolVerileri.com</strong>'da yer alan içerikler ve diğer konular hakkında her türlü
                    soru, görüş ve
                    önerileriniz için bize ulaşabilirsiniz.
                </p>
                <br>
                <p class="content-text">
                    <strong>E-Posta:</strong><span class="link_color"><a href="mailto:support@futbolverileri.com"> support@futbolverileri.com</a>
                    </span>
                </p>
                <p class="content-text">
                    <strong>Adres:</strong> 27 Old Gloucester Street, London, England, WC1N 3AX
                </p>
                <p class="content-text">
                    <strong>Twitter:</strong> <span class="link_color"><a
                        href="https://twitter.com/futbolverileri" target="_blank">
                        https://twitter.com/futbolverileri</a></span>
                </p>
                <p class="content-text">
                    <strong>Facebook:</strong> <span class="link_color"><a
                        href="https://www.facebook.com/futbolverileri/" target="_blank">
                        https://www.facebook.com/futbolverileri/</a></span>
                </p>
                <p class="content-text">
                    <strong>Instagram:</strong> <span class="link_color"><a
                        href="https://www.instagram.com/futbolverileri/" target="_blank">
                        https://www.instagram.com/futbolverileri/</a></span>
                </p>
            </v-col>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Contact",
        beforeCreate() {
            window.scrollTo(0, 0);
        }
    }
</script>

<style scoped>
    .heading {
        margin-top: 30px;
        font-size: 24px;
        font-weight: bold;
        text-align: center;
    }

    .content-text {
        color: #656565;
        font-size: 12px;
    }

    .link_color {
        color: #007bff;
    }
</style>